/*  make the footer stick to the bottom of the page */

:root {
     — space: 1.5em 0;
     — space: 2em 0;
}

.Site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.Site-content {
    flex: 1 0 auto;
    padding: var( — space) var( — space) 0;
    width: 100%;
}
.Site-content:after {
    content: ‘\00a0’;
    display: block;
    margin-top: var( — space);
    height: 0;
    visibility: hidden;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }